import React, { useState } from 'react';
import { 
  Card, 
  Form, 
  Input, 
  Button, 
  Row, 
  Col,
  message
} from 'antd';
import { SwapOutlined } from '@ant-design/icons';

const Transfer = ({ onTransfer, loading }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      if (!values.sourceId || !values.destinationId) {
        message.error('Source ID and Destination ID are required');
        return;
      }

      await onTransfer(values);
      form.resetFields();
    } catch (error) {
      message.error(error.message || 'Failed to transfer user');
    }
  };

  return (
    <Card title="User Transfer">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="sourceId"
              label="Source ID"
              rules={[{ required: true, message: 'Please enter Source ID' }]}
            >
              <Input 
                placeholder="Enter Source ID"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="destinationId"
              label="Destination ID"
              rules={[{ required: true, message: 'Please enter Destination ID' }]}
            >
              <Input 
                placeholder="Enter Destination ID"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SwapOutlined />}
            loading={loading}
          >
            Transfer
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Transfer; 