import { gql } from '@apollo/client'

export const getAllUsers = gql`
    query GetAllUsers($pageSize: Int, $page: Int, $rcUserId: String) {
        getAllUsers(pageSize: $pageSize, page: $page, rcUserId: $rcUserId) {
            users {
                id
                createdAt
                updatedAt
                email
                role
                appleSub
                googleSub
                isAgreementChecked
                premiumExpiryDate
                fcmId
                rcUserId
                store
                totalCredit {
                    dailyCredit
                    total
                }
                usedCreditCount
                isPremium
            }
            totalCount
        }
    }
`

export const getUser = gql`
    query GetUser($userId: String!) {
        getUser(userId: $userId) {
            id
            createdAt
            updatedAt
            email
            role
            appleSub
            googleSub
            isAgreementChecked
            premiumExpiryDate
            fcmId
            rcUserId
            store
            totalCredit {
                total
                dailyCredit
            }
            moderations {
                total
                blackLists {
                    id
                    prompt
                    inputImg
                    type
                    category
                    userId
                    createdAt
                }
            }
            usedCreditCount
            isPremium
        }
    }
`

export const getAllvideos = gql`
    query GetAllVideos($rcUserId: String, $pageSize: Int, $page: Int) {
        getAllVideos(rcUserId: $rcUserId, pageSize: $pageSize, page: $page) {
            totalCount
            videos {
                id
                createdAt
                updatedAt
                taskId
                model
                url
                status
                prompt
                category
                thumbnailUrl
                title
                motionScore
                style
                progressRatio
                aspectRatio
                upScale
                inputImageUrl
                errorReason
            }
        }
    }
`
export const getVideo = gql`
    query GetVideo($videoId: String!) {
        getVideo(videoId: $videoId) {
            id
            createdAt
            updatedAt
            taskId
            userId
            model
            url
            status
            prompt
            category
            thumbnailUrl
            title
            motionScore
            style
            progressRatio
            aspectRatio
            upScale
            inputImageUrl
            errorReason
            feed {
                id
                status
            }
        }
    }
`

export const getAllCreditsAdmin = gql`
    query getAllCreditsAdmin {
        getAllCredits {
            amount
            rcEventId
            id
            user {
                id
                email
            }
            date
        }
    }
`
export const getHomePageVideos = gql`
    query GetHomePageVideos {
        getHomePageVideos {
            category
            createdAt
            id
            motionScore
            progressRatio
            prompt
            status
            style
            taskId
            thumbnailUrl
            title
            updatedAt
            url
        }
    }
`
export const getAllStyles = gql`
    query GetAllStyles {
        getAllStyles {
            banner
            createdAt
            id
            name
            updatedAt
            value
        }
    }
`
export const getExplorePage = gql`
    query GetAdminExplores {
        getAdminExplores {
            aspectRatio
            createdAt
            id
            motionScore
            prompt
            style
            thumbnailUrl
            updatedAt
            gif
            category {
                name
                id
            }
            url
        }
    }
`
export const getExploreVideo = gql`
    query GetExploreVideo($exploreId: String!) {
        getExploreVideo(exploreId: $exploreId) {
            id
            url
            prompt
            thumbnailUrl
            motionScore
            style
            webmUrl
            createdAt
            updatedAt
            aspectRatio
            lowResolutionUrl
            gif
            webmUrl
            categoryId
            category{
                name
                id
            }
        }
    }
`
export const getUserCreditHistory = gql`
    query GetUserCreditHistory($userId: String!) {
        getUserCreditHistory(userId: $userId) {
            id
            userId
            creditId
            amount
            transactionId
            createdAt
            updatedAt
        }
    }
`
export const dashboardUserVideos = gql`
    query DashboardUserVideos($userId: String!) {
        dashboardUserVideos(userId: $userId) {
            id
            createdAt
            updatedAt
            taskId
            url
            status
            prompt
            category
            thumbnailUrl
            progressRatio
            aspectRatio
            upScale
            title
            motionScore
            style
        }
    }
`
export const getDashboardData = gql`
    query DashboardData {
        dashboardData {
            lumaCreditValue
            totalVideo
            totalUser
            pieChartData {
                failed
                running
                in_queue
                throttled
                succeeded
                pending
            }
            lastCreatedVideoData
            dailyVideoCount {
                date
                count
            }
            activeSubscriptions
        }
    }
`
export const getCategories = gql`
    query GetCategories {
        getCategories {
            id
            name
            video
            gif
            thumbnail
            description
            prompt
            model
            createdAt
            updatedAt
            type
            isExplore
        }
    }
`
export const getCategory = gql`
    query GetCategory($categoryId: String!) {
        getCategory(categoryId: $categoryId) {
            id
            name
            video
            gif
            thumbnail
            description
            prompt
            model
            createdAt
            updatedAt
            type
            isExplore
        }
    }
`
export const getSystem = gql`
    query GetSystem($systemType: SystemTypeEnum!) {
        getSystem(systemType: $systemType) {
            id
            system
            provider
            type
            createdAt
            updatedAt
        }
    }
`
export const getAdminFeed = gql`
    query GetAdminFeed($status: FeedStatus, $rcUserId: String, $pageSize: Int, $page: Int) {
        getAdminFeed(status: $status, rcUserId: $rcUserId, pageSize: $pageSize, page: $page) {
            feed {
                likeCount
                id
                status
                user {
                    userName
                }
                video {
                    thumbnailUrl
                    id
                }
            }
            count
        }
    }

`
export const getCredit = gql`
    query GetCredit($creditId: String!) {
        getCredit(creditId: $creditId) {
            id
            createdAt
            updatedAt
            date
            amount
            rcEventId
            productName
            productType
            expiryDate
            transactionId
            isPromo
        }
    }

`
export const supportAdmin = gql`
    query SupportAdmin($rcUserId: String!) {
        supportAdmin(rcUserId: $rcUserId) {
            credits {
                id
                createdAt
                updatedAt
                date
                amount
                rcEventId
                productName
                productType
                expiryDate
                transactionId
                isPromo
            }
            user {
                id
                createdAt
                updatedAt
                email
                role
                appleSub
                googleSub
                userName
                isAgreementChecked
                premiumExpiryDate
                fcmId
                rcUserId
                store
                firstSeenLocation
                totalCredit {
                    total
                }
                usedCreditCount
                isPremium
                videos {
                    id
                    status
                }
                lastSeenLocation
            }
            videoAnalyticData {
                succeeded
                failed
                total
                refund
            }
        }
    }
`
export const getRunwayAccounts = gql`
    query GetRunwayKeys {
        getRunwayAccounts {
            id
            accountName
            accountNo
            token
            teamId
            currentUsage
            isUnlimited
            createdAt
            updatedAt
            health {
                isActive
                error
            }
        }
    }
`

export const getRunwayAccount = gql`
    query GetRunwayAccount($keyId: String!) {
        getRunwayAccount(keyId: $keyId) {
            id
            accountName
            accountNo
            token
            teamId
            currentUsage
            isUnlimited
            createdAt
            updatedAt
            health {
                isActive
                error
            }
        }
    }
`

export const GET_USER_BY_ID = gql`
  query GetUserById($id: ID!) {
    user(id: $id) {
      id
      name
      email
      username
      phone
      address
      status
      createdAt
    }
  }
`;

export const getAllAccounts = gql`
query GetAllAccounts($type: String) {
    getAllAccounts(type: $type) {
      runwayAccounts {
        id
        accountName
        accountNo
        token
        teamId
        currentUsage
        isUnlimited
        createdAt
        updatedAt
        health {
          isActive
          error
        }
      }
      soraAccounts {
        id
        accountName
        token
        createdAt
        updatedAt
      }
      hailuoaiAccounts {
        id
        accountName
        accountNo
        token
        teamId
        currentUsage
        createdAt
        updatedAt
      }
    }
  }
`;


export const updateAccount = gql`
mutation UpdateAccount($token: String!, $accountId: String!, $type: String!) {
    updateAccount(token: $token, accountId: $accountId, type: $type) {
      success
      message
      runwayAccount {
        id
        accountName
        accountNo
        token
        teamId
        currentUsage
        isUnlimited
        createdAt
        updatedAt
        health {
          isActive
          error
        }
      }
      soraAccount {
        id
        accountName
        token
        createdAt
        updatedAt
      }
      hailuoaiAccount {
        id
        accountName
        accountNo
        token
        teamId
        currentUsage
        createdAt
        updatedAt
      }
    }
  }
`;

export const getCreditStats = gql`
  query GetCreditStats($endDate: String, $startDate: String) {
  getCreditStats(endDate: $endDate, startDate: $startDate) {
    date
    creditCounts {
      weekly
      monthly
      yearly
      tier1
      tier2
      tier3
      oneTime
    }
    totalCount
  }
}
`;
export const getBasicStats = gql`
query GetBasicStats {
    getBasicStats {
      totalUsers
      totalVideos
      activeSubscriptions
      lastVideoDate
    }
  }
`;

export const getDailyVideoStats = gql`
query GetDailyVideoStats($endDate: String, $startDate: String) {
    getDailyVideoStats(endDate: $endDate, startDate: $startDate) {
      date
      count
    }
  }
`;

export const getVideoStatusStats = gql`
query GetVideoStatusStats($endDate: String, $startDate: String) {
    getVideoStatusStats(endDate: $endDate, startDate: $startDate) {
      failed
      running
      in_queue
      throttled
      succeeded
      pending
    }
  }
`;
export const getVideoTypeStats = gql`
query GetVideoTypeStats($endDate: String, $startDate: String) {
    getVideoTypeStats(endDate: $endDate, startDate: $startDate) {
      img2Video
      text2Video
      toolPredict
      storyBoard
      total
    }
  }
`;


