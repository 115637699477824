import React from 'react';
import { Button, Layout } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import './Navbar.css';

const { Header } = Layout;

const Navbar = () => {
  const handleLogout = () => {
    // Add logout logic here
    localStorage.removeItem('token');
    window.location.reload();
  };

  return (
    <Header className="navbar">
      <div className="navbar-brand">
        <h1 className="logo">UNIVENN</h1>
      </div>
      <div className="navbar-right">
        <Button 
          type="primary" 
          icon={<LogoutOutlined />}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
    </Header>
  );
};

export default Navbar; 