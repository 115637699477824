import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { 
  Card, 
  Table, 
  Button, 
  Space, 
  message, 
  Typography,
  Tag,
  Tooltip,
  Tabs
} from 'antd';
import { 
  EditOutlined, 
  DeleteOutlined, 
  PlusOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined 
} from '@ant-design/icons';
import { getAllAccounts } from '../../graphql/queries';
import { deleteRunwayAccount } from '../../graphql/mutation';
import AccountEdit from '../../components/Account/EditAccount';
import CreateAccount from '../../components/Account/CreateAccount';
import './Account.css';

const { Title } = Typography;

const AccountPage = () => {
  const [activeTab, setActiveTab] = useState('RUNWAY');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [accountDeleteMutation] = useMutation(deleteRunwayAccount);

  const { data, loading, error, refetch } = useQuery(getAllAccounts, {
    variables: { type: activeTab }
  });

  const handleEdit = (record) => {
    setSelectedAccountId(record.id);
    setEditModalVisible(true);
  };

  const handleDelete = async (record) => {
    try {
      await accountDeleteMutation({
        variables: {
          accountId: record.id,
        },
      });
      message.success('Account deleted successfully');
      refetch();
    } catch (error) {
      message.error(`Failed to delete account: ${error.message}`);
    }
  };

  const handleCreateSuccess = () => {
    setCreateModalVisible(false);
    refetch();
  };

  const getColumns = (type) => {
    const baseColumns = [
      {
        title: 'Account Name',
        dataIndex: 'accountName',
        key: 'accountName',
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <Space>
            <Tooltip title="Edit">
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => handleEdit(record)}
                size="small"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(record)}
                size="small"
              />
            </Tooltip>
          </Space>
        ),
      },
    ];

    // Her hesap tipine özel kolonları ekle
    if (type === 'RUNWAY') {
      return [
        ...baseColumns.slice(0, 1),
        {
          title: 'Status',
          dataIndex: ['health', 'isActive'],
          key: 'status',
          render: (isActive, record) => (
            <Space direction="vertical" size="small">
              <Tag color={isActive ? 'success' : 'error'}>
                {isActive ? <><CheckCircleOutlined /> Active</> : <><CloseCircleOutlined /> Inactive</>}
              </Tag>
              {!isActive && record.health.error && (
                <Typography.Text type="danger" className="error-text">
                  {record.health.error}
                </Typography.Text>
              )}
            </Space>
          ),
        },
        {
          title: 'Usage',
          dataIndex: 'currentUsage',
          key: 'currentUsage',
        },
        ...baseColumns.slice(1),
      ];
    }

    if (type === 'SORA') {
      return baseColumns;
    }

    if (type === 'HAILUOAI') {
      return [
        ...baseColumns.slice(0, 1),
        {
          title: 'Usage',
          dataIndex: 'currentUsage',
          key: 'currentUsage',
        },
        ...baseColumns.slice(1),
      ];
    }

    return baseColumns;
  };

  const items = [
    {
      key: 'RUNWAY',
      label: 'Runway Accounts',
      children: (
        <Table
          columns={getColumns('RUNWAY')}
          dataSource={data?.getAllAccounts?.runwayAccounts}
          rowKey="id"
          loading={loading}
        />
      ),
    },
    {
      key: 'SORA',
      label: 'Sora Accounts',
      children: (
        <Table
          columns={getColumns('SORA')}
          dataSource={data?.getAllAccounts?.soraAccounts}
          rowKey="id"
          loading={loading}
        />
      ),
    },
    {
      key: 'HAILUOAI',
      label: 'HailuoAI Accounts',
      children: (
        <Table
          columns={getColumns('HAILUOAI')}
          dataSource={data?.getAllAccounts?.hailuoaiAccounts}
          rowKey="id"
          loading={loading}
        />
      ),
    },
  ];

  if (error) {
    message.error('Error fetching accounts');
  }

  return (
    <div className="account-container">
      <Card
        title="Account Management"
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setCreateModalVisible(true)}
          >
            Add Account
          </Button>
        }
      >
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={items}
          className="account-tabs"
        />
      </Card>

      <AccountEdit
        open={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        accountId={selectedAccountId}
        accountType={activeTab}
      />

      <CreateAccount
        open={createModalVisible}
        onClose={() => setCreateModalVisible(false)}
        createSuccess={handleCreateSuccess}
        accountType={activeTab}
      />
    </div>
  );
};

export default AccountPage; 