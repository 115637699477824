import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { 
  Table, 
  Card, 
  Input, 
  Select, 
  Space, 
  Image,
  Row,
  Col,
  Tag
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getAdminFeed } from '../../graphql/queries';
import './Feed.css';

const { Option } = Select;

const feedStatusArray = [
  { value: 'PENDING', name: 'Pending', color: 'gold' },
  { value: 'CONFIRMED', name: 'Confirmed', color: 'green' },
  { value: 'REJECTED', name: 'Rejected', color: 'red' }
];

const getStatusTag = (status) => {
  const statusConfig = feedStatusArray.find(item => item.value === status) || {
    value: status,
    name: status,
    color: 'default'
  };
  
  return (
    <Tag color={statusConfig.color} style={{ fontSize: '14px', padding: '4px 8px' }}>
      {statusConfig.name}
    </Tag>
  );
};

const Feed = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState('');
  const [status, setStatus] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
  });

  const { loading, data, refetch } = useQuery(getAdminFeed, {
    variables: {
      status: status || null,
      rcUserId: searchInput || null,
    },
    fetchPolicy: 'network-only',
  });

  const columns = [
    {
      title: 'Feed ID',
      dataIndex: 'id',
      key: 'id',
      width: '15%',
    },
    {
      title: 'Like Count',
      dataIndex: 'likeCount',
      key: 'likeCount',
      width: '10%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status) => getStatusTag(status),
    },
    {
      title: 'User Name',
      dataIndex: 'userName',
      key: 'userName',
      width: '20%',
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnailUrl',
      key: 'thumbnailUrl',
      width: '25%',
      render: (thumbnailUrl) => (
        <Image
          src={thumbnailUrl}
          alt="Thumbnail"
          style={{
            width: '120px',
            height: '80px',
            objectFit: 'cover',
            borderRadius: '4px',
          }}
        />
      ),
    },
  ];

  const handleSearch = (value) => {
    setSearchInput(value);
    refetch({
      status: status || null,
      rcUserId: value || null,
    });
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    refetch({
      status: value || null,
      rcUserId: searchInput || null,
    });
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleRowClick = (record) => {
    navigate(`/video/${record.videoId}`);
  };

  const dataSource = data?.getAdminFeed?.feed?.map(item => ({
    key: item.id,
    id: item.id,
    videoId: item.video.id,
    likeCount: item.likeCount,
    status: item.status,
    thumbnailUrl: item.video.thumbnailUrl,
    userName: item.user.userName || 'N/A',
  })) || [];

  return (
    <div className="feed-container">
      <Card title="Feed">
        <Row gutter={24} className="filter-row">
          <Col span={16}>
            <Input
              placeholder="Search by RevenueCat User ID"
              prefix={<SearchOutlined />}
              onChange={(e) => handleSearch(e.target.value)}
              allowClear
            />
          </Col>
          <Col span={8}>
            <Select
              placeholder="Filter by Status"
              style={{ width: '100%' }}
              onChange={handleStatusChange}
              value={status}
              allowClear
            >
              {feedStatusArray.map(item => (
                <Option key={item.value} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          pagination={{
            ...pagination,
            total: dataSource.length,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            style: { cursor: 'pointer' }
          })}
        />
      </Card>
    </div>
  );
};

export default Feed; 