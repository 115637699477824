import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getUser, getUserCreditHistory, dashboardUserVideos } from '../../graphql/queries';
import { Card, Descriptions, Tag, Spin, Alert, Table, Image, Tabs, Tooltip, Space, Button, message } from 'antd';
import { CustomerServiceOutlined } from '@ant-design/icons';
import './UserDetail.css';

const { TabPane } = Tabs;

const UserDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading: userLoading, error: userError, data: userData } = useQuery(getUser, {
    variables: { userId: id },
  });
  const { data: creditData } = useQuery(getUserCreditHistory, { 
    variables: { userId: id } 
  });
  const { data: videoData } = useQuery(dashboardUserVideos, { 
    variables: { userId: id } 
  });

  if (userLoading) return <Spin size="large" className="user-detail-loading" />;
  if (userError) return <Alert type="error" message={`Error: ${userError.message}`} />;

  const user = userData.getUser;
  const creditHistory = creditData?.getUserCreditHistory || [];
  const videos = videoData?.dashboardUserVideos || [];

  const truncateText = (text, maxLength = 30) => {
    if (!text) return '-';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const creditColumns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      width: 250,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
    },
    {
      title: 'Credit ID',
      dataIndex: 'creditId',
      key: 'creditId',
      width: 250,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (date) => new Date(date).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }),
    },
  ];

  const videoColumns = [
    {
      title: 'Prompt',
      dataIndex: 'prompt',
      key: 'prompt',
      width: 400,
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnailUrl',
      key: 'thumbnailUrl',
      width: 150,
      render: (url) => (
        <Image 
          src={url} 
          alt="Thumbnail" 
          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
          preview={{
            maskClassName: 'customize-mask',
            mask: <div>View</div>
          }}
        />
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (date) => new Date(date).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }),
    },
  ];

  const handleSupportClick = () => {
    if (user?.rcUserId) {
      navigate(`/support?rcUserId=${user.rcUserId}`);
    } else {
      message.warning('RevenueCat User ID not available');
    }
  };

  return (
    <div className="user-detail-container">
      <Card
        title={
          <Space>
            <span>User Details</span>
            <Button
              type="primary"
              icon={<CustomerServiceOutlined />}
              onClick={handleSupportClick}
              disabled={!user?.rcUserId}
            >
              Go to Support
            </Button>
          </Space>
        }
      >
        <Descriptions 
          bordered 
          column={2}
          labelStyle={{ width: '200px', whiteSpace: 'nowrap' }}
          contentStyle={{ width: '300px' }}
        >
          <Descriptions.Item label="RevenueCat Customer ID">{user.rcUserId || '-'}</Descriptions.Item>
          <Descriptions.Item label="User ID">{user.id}</Descriptions.Item>
          
          <Descriptions.Item label="Store">{user.store || '-'}</Descriptions.Item>
          <Descriptions.Item label="Role">
            <Tag color={user.role === 'ADMIN' ? 'green' : 'blue'}>
              {user.role}
            </Tag>
          </Descriptions.Item>

          <Descriptions.Item label="Premium Status">
            <Tag color={user.isPremium ? 'gold' : 'default'}>
              {user.isPremium ? 'Premium' : 'Regular'}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Premium Expiry Date">
            {user.premiumExpiryDate ? new Date(user.premiumExpiryDate).toLocaleString('tr-TR', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            }) : '-'}
          </Descriptions.Item>

          <Descriptions.Item label="Credits" span={2}>
            <Descriptions 
              bordered 
              size="small"
              labelStyle={{ width: '150px', whiteSpace: 'nowrap' }}
            >
              <Descriptions.Item label="Total Credits">{user.totalCredit?.total || 0}</Descriptions.Item>
              <Descriptions.Item label="Used Credits">{user.usedCreditCount || 0}</Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>

          <Descriptions.Item label="Moderations" span={2}>
            <Descriptions 
              bordered 
              size="small"
              labelStyle={{ width: '150px', whiteSpace: 'nowrap' }}
            >
              <Descriptions.Item label="Total Moderations">{user.moderations?.total || 0}</Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
        </Descriptions>

        <Tabs defaultActiveKey="1" className="detail-tabs">
          <TabPane tab="Credit History" key="1">
            <Table
              columns={creditColumns}
              dataSource={creditHistory}
              rowKey="id"
              pagination={{ pageSize: 5 }}
              scroll={{ x: 'max-content' }}
            />
          </TabPane>
          
          <TabPane tab="Videos" key="2">
            <Table
              columns={videoColumns}
              dataSource={videos}
              rowKey="id"
              pagination={{ pageSize: 5 }}
              scroll={{ x: 'max-content' }}
            />
          </TabPane>

          {user.moderations?.total > 0 && (
            <TabPane tab="Blacklist" key="3">
              <Table
                columns={[
                  { 
                    title: 'ID', 
                    dataIndex: 'id', 
                    key: 'id',
                    width: '10%'
                  },
                  { 
                    title: 'Prompt', 
                    dataIndex: 'prompt', 
                    key: 'prompt',
                    width: '25%',
                    render: (text) => (
                      <Tooltip title={text} placement="topLeft">
                        <span className="truncated-text">{truncateText(text)}</span>
                      </Tooltip>
                    )
                  },
                  { 
                    title: 'Input Image', 
                    dataIndex: 'inputImg', 
                    key: 'inputImg',
                    width: '15%',
                    render: (url) => url ? (
                      <Image
                        src={url}
                        alt="Input"
                        style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                        preview={{
                          maskClassName: 'customize-mask',
                          mask: <div>View</div>
                        }}
                      />
                    ) : '-'
                  },
                  { 
                    title: 'Type', 
                    dataIndex: 'type', 
                    key: 'type',
                    width: '15%'
                  },
                  { 
                    title: 'Category', 
                    dataIndex: 'category', 
                    key: 'category',
                    width: '15%'
                  },
                  { 
                    title: 'Created At', 
                    dataIndex: 'createdAt', 
                    key: 'createdAt',
                    width: '15%',
                    render: (date) => new Date(date).toLocaleString('tr-TR', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })
                  },
                ]}
                dataSource={user.moderations.blackLists}
                rowKey="id"
                pagination={{ pageSize: 5 }}
              />
            </TabPane>
          )}
        </Tabs>
      </Card>
    </div>
  );
};

export default UserDetail; 