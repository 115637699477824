import { notification } from 'antd';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';

export const showSnackBar = (type, message, description = '') => {
  notification[type]({
    message,
    description,
    placement: 'topRight',
    duration: 3,
    style: {
      borderRadius: '8px',
    },
    icon: type === 'success' ? <CheckCircleOutlined /> : <WarningOutlined />,
  });
}; 