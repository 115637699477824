import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { useMutation } from '@apollo/client';
import { Login as LoginMutation } from '../../graphql/mutation';
import './Login.css';

const Login = () => {
  const [loading, setLoading] = useState(false);
  
  const [login] = useMutation(LoginMutation, {
    onCompleted: (data) => {
      const { token } = data.login;
      localStorage.setItem('token', token);
      window.location.reload();
      message.success('Login successful!');
    },
    onError: (error) => {
      setLoading(false);
      message.error(error.message || 'Login failed');
    },
  });

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await login({
        variables: {
          email: values.email,
          password: values.password,
        },
      });
    } catch (error) {
      // Error is handled in onError callback
    }
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <h1 className="login-title">UNIVENN</h1>
        <Form
          name="login"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
              {
                type: 'email',
                message: 'Please enter a valid email!',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>

          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              size="large" 
              block
              loading={loading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login; 