import React, { useState } from 'react';
import { Table, Input, Space, Card, Tag } from 'antd';
import { useQuery } from '@apollo/client';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getAllUsers } from '../../graphql/queries';
import './Users.css';

const { Search } = Input;

const Users = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const { loading, data, refetch } = useQuery(getAllUsers, {
    variables: {
      page: pagination.current,
      pageSize: pagination.pageSize,
      rcUserId: searchText || null,
    },
  });

  const columns = [
    {
      title: 'RC User ID',
      dataIndex: 'rcUserId',
      key: 'rcUserId',
      filterable: true,
      render: (text) => text || 'N/A',
    },
    {
      title: 'Premium Status',
      dataIndex: 'isPremium',
      key: 'isPremium',
      filters: [
        { text: 'Premium', value: true },
        { text: 'Regular', value: false },
      ],
      render: (isPremium) => (
        <Tag color={isPremium ? 'gold' : 'default'}>
          {isPremium ? 'Premium' : 'Regular'}
        </Tag>
      ),
    },
    {
      title: 'Credits',
      dataIndex: 'totalCredit',
      key: 'totalCredit',
      render: (totalCredit) => totalCredit?.total || 0,
      sorter: (a, b) => (a.totalCredit?.total || 0) - (b.totalCredit?.total || 0),
    },
    {
      title: 'Used Credits',
      dataIndex: 'usedCreditCount',
      key: 'usedCreditCount',
      render: (count) => count || 0,
      sorter: (a, b) => (a.usedCreditCount || 0) - (b.usedCreditCount || 0),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => date ? new Date(date).toLocaleDateString() : 'N/A',
      sorter: (a, b) => new Date(a.createdAt || 0) - new Date(b.createdAt || 0),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setPagination({ ...pagination, current: 1 });
    refetch({
      page: 1,
      pageSize: pagination.pageSize,
      rcUserId: value || null,
    });
  };

  const handleRowClick = (record) => {
    navigate(`/user/${record.id}`);
  };

  return (
    <div className="users-container">
      <Card>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Search
            placeholder="Search by RC User ID"
            allowClear
            enterButton={<SearchOutlined />}
            size="large"
            onSearch={handleSearch}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Table
            columns={columns}
            dataSource={data?.getAllUsers?.users}
            loading={loading}
            rowKey="id"
            pagination={{
              ...pagination,
              total: data?.getAllUsers?.totalCount,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} users`,
            }}
            onChange={handleTableChange}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              style: { cursor: 'pointer' }
            })}
          />
        </Space>
      </Card>
    </div>
  );
};

export default Users; 