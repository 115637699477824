import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import './index.css';
import App from './App';
import 'antd/dist/reset.css';

const getToken = () => {
  const token = window.localStorage.getItem('token');
  
  if (token) {
    // Remove double quotes if present
    const cleanToken = token.replace(/^"|"$/g, '');
    return `Bearer ${cleanToken}`;
  }
  return 'Bearer null';
};

// Initialize Apollo Client
const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
  headers: { Authorization: getToken() },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
