import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { createRunwayAccount } from '../../graphql/mutation';
import './Account.css';

const CreateAccount = ({ open, onClose, createSuccess }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [createRunwayAccountMutation] = useMutation(createRunwayAccount);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await createRunwayAccountMutation({
        variables: {
          accountName: values.accountName,
          token: values.token,
          teamId: Number(values.teamId),
        },
      });
      message.success('Account created successfully');
      createSuccess();
      form.resetFields();
      onClose();
    } catch (error) {
      message.error(`Failed to create account: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <div className="modal-header">
          <span>Create Account</span>
          <Button 
            type="text" 
            icon={<CloseOutlined />} 
            onClick={onClose}
            className="close-button"
          />
        </div>
      }
      open={open}
      onCancel={onClose}
      footer={null}
      width={600}
      className="account-modal"
      closeIcon={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="account-form"
      >
        <Form.Item
          name="accountName"
          label="Account Name"
          rules={[{ required: true, message: 'Please input account name!' }]}
        >
          <Input placeholder="Enter account name" />
        </Form.Item>

        <Form.Item
          name="token"
          label="Token"
          rules={[{ required: true, message: 'Please input token!' }]}
        >
          <Input.Password placeholder="Enter token" />
        </Form.Item>

        <Form.Item
          name="teamId"
          label="Team ID"
          rules={[{ required: true, message: 'Please input team ID!' }]}
        >
          <Input type="number" placeholder="Enter team ID" />
        </Form.Item>

        <Form.Item className="form-actions">
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateAccount; 