import React from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  UserOutlined,
  VideoCameraOutlined,
  CompassOutlined,
  AppstoreOutlined,
  HomeOutlined,
  CustomerServiceOutlined,
  TeamOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DashboardOutlined,
  SettingOutlined
} from '@ant-design/icons';
import './SideMenu.css';

const { Sider } = Layout;

const SideMenu = ({ collapsed, onCollapse }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { 
      key: '/', 
      label: 'Dashboard', 
      icon: <DashboardOutlined />, 
      onClick: () => navigate('/') 
    },
    { 
      key: 'users', 
      label: 'Users', 
      icon: <UserOutlined />, 
      onClick: () => navigate('/users') 
    },
    { key: 'videos', label: 'Videos', icon: <VideoCameraOutlined />, onClick: () => navigate('/videos') },
    { key: 'explore', label: 'Explore', icon: <CompassOutlined />, onClick: () => navigate('/explore') },
    { key: 'categories', label: 'Categories', icon: <AppstoreOutlined />, onClick: () => navigate('/categories') },
    { key: 'feed', label: 'Feed', icon: <HomeOutlined />, onClick: () => navigate('/feed') },
    { key: 'support', label: 'Support', icon: <CustomerServiceOutlined />,onClick: () => navigate('/support')},
    { key: 'accounts', label: 'Accounts', icon: <TeamOutlined />,onClick: () => navigate('/accounts') },
    { 
      key: 'system', 
      label: 'System', 
      icon: <SettingOutlined />, 
      onClick: () => navigate('/system') 
    },
  ];

  return (
    <Sider 
      collapsed={collapsed} 
      className="side-menu"
      trigger={null}
    >
      <div className="toggle-button" onClick={onCollapse}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
        items={menuItems}
        onClick={({ key }) => navigate(key)}
      />
    </Sider>
  );
};

export default SideMenu; 