import React, { useState, useEffect } from 'react';
import { 
  Card, 
  Input, 
  Button, 
  Space, 
  message, 
  Typography, 
  List,
  Modal,
  Empty,
  Alert,
  Divider,
  Tabs,
  Table,
  Form,
  InputNumber,
  notification,
  Select,
  Row,
  Col
} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { deleteUserData, userTransfer, sendFcm, updateCredit } from '../../graphql/mutation';
import { getUser, getUserCreditHistory, supportAdmin } from '../../graphql/queries';
import { 
  DeleteOutlined, 
  ExclamationCircleOutlined, 
  SearchOutlined,
  SwapOutlined,
  NotificationOutlined,
  CloseOutlined
} from '@ant-design/icons';
import VideoAnalytic from '../../components/Support/VideoAnalytic';
import Transfer from '../../components/Support/Transfer';
import { useSearchParams } from 'react-router-dom';
import './Support.css';

const { Title, Text } = Typography;
const { Search } = Input;
const { TabPane } = Tabs;

const CreditDetailModal = ({ credit, visible, onClose, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (values) => {
    setLoading(true);
    try {
      await onUpdate(credit.id, values.amount, values.expiryDate);
      onClose();
    } catch (error) {
      message.error('Failed to update credit');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (credit) {
      form.setFieldsValue({
        amount: credit.amount,
        expiryDate: credit.expiryDate ? credit.expiryDate.split('T')[0] : undefined
      });
    }
  }, [credit, form]);

  return (
    <Modal
      title="Credit Details"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleUpdate}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="ID">
              <Input disabled value={credit?.id} />
            </Form.Item>
            <Form.Item label="Created At">
              <Input disabled value={credit?.createdAt ? new Date(credit.createdAt).toLocaleString() : ''} />
            </Form.Item>
            <Form.Item label="Updated At">
              <Input disabled value={credit?.updatedAt ? new Date(credit.updatedAt).toLocaleString() : ''} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Transaction ID">
              <Input disabled value={credit?.transactionId} />
            </Form.Item>
            <Form.Item label="Date">
              <Input disabled value={credit?.date ? new Date(credit.date).toLocaleString() : ''} />
            </Form.Item>
            <Form.Item label="RC Event ID">
              <Input disabled value={credit?.rcEventId} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Product Name">
              <Input disabled value={credit?.productName} />
            </Form.Item>
            <Form.Item label="Product Type">
              <Input disabled value={credit?.productType} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[{ required: true, message: 'Please enter amount' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="expiryDate"
              label="Expiry Date"
            >
              <Input type="date" />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ textAlign: 'right', marginTop: 24 }}>
          <Space>
            <Button onClick={onClose}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save Changes
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

const Support = () => {
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [userId, setUserId] = useState('');
  const [creditData, setCreditData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [deletedItems, setDeletedItems] = useState([]);
  const [showDeletedItems, setShowDeletedItems] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState(null);
  const [creditModalVisible, setCreditModalVisible] = useState(false);

  const { data, loading: userLoading, error, refetch } = useQuery(supportAdmin, {
    variables: { rcUserId: userId },
    skip: !userId,
    onCompleted: (data) => {
      setCreditData(data?.supportAdmin?.credits || []);
      setUserData(data?.supportAdmin?.user || null);
      setVideoData(data?.supportAdmin?.videoAnalyticData || null);
    }
  });

  const [deleteUserDataMutation, { loading: deleteLoading }] = useMutation(deleteUserData);
  const [transferCredit] = useMutation(userTransfer);
  const [sendPushNotification] = useMutation(sendFcm);
  const [updateCreditMutation] = useMutation(updateCredit);

  useEffect(() => {
    const rcUserId = searchParams.get('rcUserId');
    if (rcUserId) {
      setUserId(rcUserId);
    }
  }, [searchParams]);

  const handleSearch = (value) => {
    if (!value) {
      message.error('Please enter a user ID');
      return;
    }
    setUserId(value);
  };

  const handleDelete = () => {
    Modal.confirm({
      title: 'Delete User Data',
      icon: <DeleteOutlined style={{ color: '#ff4d4f' }} />,
      content: (
        <Space direction="vertical" size={4} style={{ width: '100%', marginTop: 8 }}>
          <Text type="secondary" style={{ fontSize: '14px' }}>
            Are you sure you want to delete this user's data?
          </Text>
          <div style={{ 
            background: '#f5f5f5', 
            padding: '8px 12px', 
            borderRadius: 4,
            marginTop: 4
          }}>
            <Text copyable style={{ fontSize: '13px' }}>{userData.id}</Text>
          </div>
        </Space>
      ),
      okText: 'Delete',
      okButtonProps: {
        danger: true,
        icon: <DeleteOutlined />
      },
      cancelText: 'Cancel',
      width: 400,
      centered: true,
      maskClosable: true,
      onOk: async () => {
        try {
          const { data } = await deleteUserDataMutation({
            variables: { userId: userData.id }
          });

          if (data.deleteUserData.success) {
            setDeletedItems(data.deleteUserData.deletedItems);
            setShowDeletedItems(true);
            message.success('User data deleted successfully');
            setUserId('');
          } else {
            message.error(data.deleteUserData.message);
          }
        } catch (error) {
          message.error('Failed to delete user data: ' + error.message);
        }
      },
    });
  };

  const handleTransfer = async (values) => {
    try {
      const { data } = await transferCredit({
        variables: {
          sourceId: values.sourceId,
          destinationId: values.destinationId,
        }
      });
      if (data.userTransfer) {
        message.success('Credits transferred successfully');
      } else {
        message.error('Transfer failed');
      }
    } catch (error) {
      message.error('Failed to transfer users.: ' + error.message);
      throw error;
    }
  };

  const handlePushNotification = async (values) => {
    try {
      const { data } = await sendPushNotification({
        variables: {
          fcmId: values.fcmId,
          title: values.title,
          message: values.message
        }
      });
      if (data.sendFcm) {
        notification.success({
          message: 'Success',
          description: 'Push notification has been sent successfully'
        });
        form.resetFields();
      } else {
        message.error('Failed to send notification');
      }
    } catch (error) {
      message.error('Failed to send notification: ' + error.message);
    }
  };

  const handleUpdateCredit = async (creditId, amount, expiryDate) => {
    try {
      const { data } = await updateCreditMutation({
        variables: {
          creditId,
          amount: parseFloat(amount),
          expiryDate: expiryDate ? new Date(expiryDate).toISOString() : undefined
        }
      });
      
      if (data.updateCredit.id) {
        message.success('Credits updated successfully');
        // Refresh the data
        refetch();
      }
    } catch (error) {
      message.error('Failed to update credits: ' + error.message);
      throw error;
    }
  };

  const creditColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 180,
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 100,
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Type',
      dataIndex: 'productType',
      key: 'productType',
      width: 100,
    },
    {
      title: 'Expiry',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      width: 180,
      render: (date) => date ? new Date(date).toLocaleString() : '-',
    }
  ];

  const renderUserInfo = () => {
    if (!userId) return null;
    if (userLoading) return <div className="loading-placeholder">Loading user information...</div>;
    if (error) return <Alert message={error.message} type="error" />;
    if (!userData) return <Empty description="No user found with this ID" />;

    const userInfoItems = [
      { label: 'ID', value: userData.id, copyable: true },
      { label: 'Email', value: userData.email, copyable: true },
      { label: 'Role', value: userData.role },
      { label: 'Created At', value: new Date(userData.createdAt).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }) },
      { label: 'RC User ID', value: userData.rcUserId, copyable: true },
      { label: 'Premium', value: userData.isPremium ? 'Yes' : 'No' },
      { label: 'Total Credits', value: userData.totalCredit?.total || 0 },
      { label: 'Used Credits', value: userData.usedCreditCount || 0 },
      { label: 'Store', value: userData.store || '-' },
      { label: 'Premium Expiry', value: userData.premiumExpiryDate ? new Date(userData.premiumExpiryDate).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }) : '-' }
    ];

    return (
      <Card className="user-info-card">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <div>
            <Title level={5}>User Information</Title>
            <Row gutter={[24, 12]}>
              {userInfoItems.map((item, index) => (
                <Col span={8} key={index}>
                  <Space direction="vertical" size={0} style={{ width: '100%' }}>
                    <Text type="secondary" style={{ fontSize: '12px' }}>{item.label}</Text>
                    <Text copyable={item.copyable} style={{ fontSize: '14px' }}>{item.value}</Text>
                  </Space>
                </Col>
              ))}
            </Row>
          </div>

          {videoData && (
            <div>
              <Title level={5}>Video Analytics</Title>
              <VideoAnalytic videoData={videoData} />
            </div>
          )}

          <div>
            <Button 
              type="primary" 
              danger 
              icon={<DeleteOutlined />} 
              onClick={handleDelete}
              loading={deleteLoading}
            >
              Delete User Data
            </Button>
          </div>
        </Space>
      </Card>
    );
  };

  const renderTransferTab = () => (
    <Form form={form} layout="vertical" onFinish={handleTransfer}>
      <Form.Item
        name="sourceId"
        label="Source User ID"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="destinationId"
        label="Destination User ID"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Button type="primary" htmlType="submit" icon={<SwapOutlined />}>
        User Transfer
      </Button>
    </Form>
  );

  const renderPushTab = () => (
    <Form form={form} layout="vertical" onFinish={handlePushNotification}>
      <Form.Item
        name="fcmId"
        label="FCM ID"
        rules={[{ required: true, message: 'Please enter FCM ID' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="title"
        label="Notification Title"
        rules={[{ required: true, message: 'Please enter notification title' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="message"
        label="Notification Message"
        rules={[{ required: true, message: 'Please enter notification message' }]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Button type="primary" htmlType="submit" icon={<NotificationOutlined />}>
        Send Notification
      </Button>
    </Form>
  );

  return (
    <div className="support-container">
      <Card>
        <Tabs defaultActiveKey="user">
          <TabPane tab="User Management" key="user">
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <div>
                <Title level={5}>Search User</Title>
                <Search
                  placeholder="Enter RevenueCat User ID"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  onSearch={handleSearch}
                  enterButton={<SearchOutlined />}
                  loading={userLoading}
                />
              </div>

              {error && (
                <Alert message={error.message} type="error" showIcon />
              )}

              {renderUserInfo()}

              {userData && (
                <>
                  <Divider>Credit History</Divider>
                  <Table
                    columns={creditColumns}
                    dataSource={creditData}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                    size="middle"
                    onRow={(record) => ({
                      onClick: () => {
                        setSelectedCredit(record);
                        setCreditModalVisible(true);
                      },
                      style: { cursor: 'pointer' }
                    })}
                    style={{ 
                      maxWidth: '800px', 
                      margin: '0 auto' 
                    }}
                  />
                  <CreditDetailModal
                    credit={selectedCredit}
                    visible={creditModalVisible}
                    onClose={() => {
                      setCreditModalVisible(false);
                      setSelectedCredit(null);
                    }}
                    onUpdate={handleUpdateCredit}
                  />
                </>
              )}
            </Space>
          </TabPane>

          <TabPane tab="User Transfer" key="transfer">
            <Transfer onTransfer={handleTransfer} />
          </TabPane>

          <TabPane tab="Push Notification" key="push">
            {renderPushTab()}
          </TabPane>
        </Tabs>

        <Modal
          title="Deleted Items"
          open={showDeletedItems}
          onOk={() => setShowDeletedItems(false)}
          onCancel={() => setShowDeletedItems(false)}
          footer={[
            <Button key="ok" type="primary" onClick={() => setShowDeletedItems(false)}>
              OK
            </Button>
          ]}
          width={400}
          centered
        >
          <List
            size="small"
            dataSource={deletedItems}
            renderItem={item => (
              <List.Item>
                <Text style={{ fontSize: '13px' }}>{item}</Text>
              </List.Item>
            )}
            style={{
              maxHeight: '300px',
              overflowY: 'auto'
            }}
          />
        </Modal>
      </Card>
    </div>
  );
};

export default Support; 