import React, { useState } from 'react';
import { Layout } from 'antd';
import Navbar from './components/Navbar/Navbar';
import SideMenu from './components/SideMenu/SideMenu';
import Login from './pages/Login/Login';
import Users from './pages/Users/Users';
import UserDetail from './pages/Users/UserDetail';
import Videos from './pages/Videos/Videos';
import VideoDetail from './pages/Videos/VideoDetail';
import Explore from './pages/Explore/Explore';
import ExploreDetail from './pages/Explore/ExploreDetail';
import CreateExplore from './pages/Explore/CreateExplore';
import Categories from './pages/Categories/Categories';
import CategoryDetail from './pages/Categories/CategoryDetail';
import CreateCategory from './pages/Categories/CreateCategory';
import Feed from './pages/Feed/Feed';
import Support from './pages/Support/Support';
import AccountPage from './pages/Account/Account';
import Dashboard from './pages/Dashboard/Dashboard';
import SystemPage from './pages/System/System';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

const { Content } = Layout;

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const token = localStorage.getItem('token');

  if (!token) {
    return <Login />;
  }

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Router>
      <Layout className="app">
        <Navbar />
        <Layout>
          <SideMenu collapsed={collapsed} onCollapse={toggleSider} />
          <Content className={`content ${collapsed ? 'content-collapsed' : ''}`}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/user/:id" element={<UserDetail />} />
              <Route path="/videos" element={<Videos />} />
              <Route path="/video/:id" element={<VideoDetail />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/explore/create" element={<CreateExplore />} />
              <Route path="/explore/:id" element={<ExploreDetail />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/categories/:id" element={<CategoryDetail />} />
              <Route path="/categories/create" element={<CreateCategory />} />
              <Route path="/feed" element={<Feed />} />
              <Route path="/support" element={<Support />} />
              <Route path="/accounts" element={<AccountPage />} />
              <Route path="/system" element={<SystemPage />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
}

export default App;
