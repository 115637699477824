import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { createCategory, getSignedUrl, getObjectUrl } from '../../graphql/mutation';
import { 
  Card, 
  Form, 
  Input, 
  Select, 
  Upload, 
  Button, 
  Space, 
  message, 
  Row, 
  Col,
  Image 
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { uploadToR2 } from '../../utils/uploadToR2';
import { showSnackBar } from '../../components/SnackBar/SnackBar';
import './CreateCategory.css';

const { Option } = Select;
const { TextArea } = Input;

const categoryTypes = [
  { value: 'TRANSITION', label: 'Transition' },
  { value: 'MORPHING', label: 'Morphing' },
  { value: 'HUGGING', label: 'Hugging' },
  { value: 'IMAGE2VIDEO', label: 'Image to Video' },
];

const CreateCategory = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [files, setFiles] = useState({
    video: null,
    thumbnail: null,
    gif: null,
  });
  const [previews, setPreviews] = useState({
    video: null,
    thumbnail: null,
    gif: null,
  });
  const [loading, setLoading] = useState(false);

  const [createCategoryMutation] = useMutation(createCategory);
  const [getSignedUrlMutation] = useMutation(getSignedUrl);
  const [getObjectUrlMutation] = useMutation(getObjectUrl);

  const handleFileChange = (type, file) => {
    if (file) {
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        showSnackBar('error', 'File Too Large', `${type} must be smaller than 100MB`);
        return false;
      }

      setFiles(prev => ({ ...prev, [type]: file }));
      setPreviews(prev => ({ 
        ...prev, 
        [type]: URL.createObjectURL(file) 
      }));
      showSnackBar('success', 'File Selected', `${type} file selected successfully`);
    }
    return false;
  };

  const getSignedUrls = async () => {
    const urls = {};
    for (const [type, file] of Object.entries(files)) {
      if (file) {
        const { data } = await getSignedUrlMutation({
          variables: { fileName: file.name }
        });
        urls[type] = data.getSignedUrl;
      }
    }
    return urls;
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      
      // Validate required files
      if (!files.video || !files.thumbnail || !files.gif) {
        showSnackBar('error', 'Missing Files', 'All media files are required');
        return;
      }

      const signedUrls = await getSignedUrls();
      
      // Upload files
      const uploadPromises = Object.entries(signedUrls).map(([type, urlData]) => 
        uploadToR2(files[type], urlData.url, urlData.contentType)
      );
      
      showSnackBar('info', 'Uploading Files', 'Please wait while files are being uploaded...');
      await Promise.all(uploadPromises);

      // Get permanent URLs
      const objectUrls = {};
      for (const [type, urlData] of Object.entries(signedUrls)) {
        const { data } = await getObjectUrlMutation({
          variables: { key: urlData.id, eTag: 'etag' }
        });
        objectUrls[type] = data.getObjectUrl;
      }

      // Create category
      await createCategoryMutation({
        variables: {
          name: values.name,
          description: values.description,
          prompt: values.prompt,
          model: values.model,
          type: values.type,
          isExplore: values.isExplore,
          video: objectUrls.video,
          thumbnail: objectUrls.thumbnail,
          gif: objectUrls.gif,
        }
      });

      showSnackBar('success', 'Success', 'Category created successfully');
      navigate('/categories');
    } catch (error) {
      showSnackBar('error', 'Error', error.message || 'Failed to create category');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-category-container">
      <Card title="Create Category">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please enter name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Please enter description' }]}
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item
                name="prompt"
                label="Prompt"
                rules={[{ required: true, message: 'Please enter prompt' }]}
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item
                name="model"
                label="Model"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: 'Please select type' }]}
              >
                <Select placeholder="Select type">
                  {categoryTypes.map(type => (
                    <Option key={type.value} value={type.value}>
                      {type.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="isExplore"
                label="Is Explore"
                rules={[{ required: true, message: 'Please select is explore' }]}
              >
                <Select placeholder="Select is explore">
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="Video"
                required
                tooltip="Video is required"
              >
                <Upload
                  beforeUpload={file => handleFileChange('video', file)}
                  showUploadList={false}
                  accept="video/*"
                >
                  <Button icon={<UploadOutlined />}>Select Video</Button>
                </Upload>
                {previews.video && (
                  <video
                    src={previews.video}
                    controls
                    className="preview-video"
                  />
                )}
              </Form.Item>

              <Form.Item 
                label="Thumbnail"
                required
                tooltip="Thumbnail is required"
              >
                <Upload
                  beforeUpload={file => handleFileChange('thumbnail', file)}
                  showUploadList={false}
                  accept="image/*"
                >
                  <Button icon={<UploadOutlined />}>Select Thumbnail</Button>
                </Upload>
                {previews.thumbnail && (
                  <Image
                    src={previews.thumbnail}
                    alt="Thumbnail"
                    className="preview-image"
                  />
                )}
              </Form.Item>

              <Form.Item 
                label="GIF"
                required
                tooltip="GIF is required"
              >
                <Upload
                  beforeUpload={file => handleFileChange('gif', file)}
                  showUploadList={false}
                  accept="image/gif"
                >
                  <Button icon={<UploadOutlined />}>Select GIF</Button>
                </Upload>
                {previews.gif && (
                  <Image
                    src={previews.gif}
                    alt="GIF"
                    className="preview-image"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Space>
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={loading}
              >
                Create
              </Button>
              <Button onClick={() => navigate('/categories')}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CreateCategory; 