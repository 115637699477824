import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { getCategory } from '../../graphql/queries';
import { updateCategory, deleteCategory, getSignedUrl, getObjectUrl } from '../../graphql/mutation';
import { 
  Card, 
  Form, 
  Input, 
  Select, 
  Upload, 
  Button, 
  Space, 
  message, 
  Row, 
  Col,
  Image 
} from 'antd';
import { UploadOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { uploadToR2 } from '../../utils/uploadToR2';
import { showSnackBar } from '../../components/SnackBar/SnackBar';
import './CategoryDetail.css';

const { Option } = Select;
const { TextArea } = Input;

const categoryTypes = [
  { value: 'TRANSITION', label: 'Transition' },
  { value: 'MORPHING', label: 'Morphing' },
  { value: 'HUGGING', label: 'Hugging' },
  { value: 'IMAGE2VIDEO', label: 'Image to Video' },
];

const CategoryDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [files, setFiles] = useState({
    video: null,
    thumbnail: null,
    gif: null,
  });
  const [previews, setPreviews] = useState({
    video: null,
    thumbnail: null,
    gif: null,
  });
  const [loading, setLoading] = useState(false);

  const { data: categoryData } = useQuery(getCategory, {
    variables: { categoryId: id },
  });

  const [updateCategoryMutation] = useMutation(updateCategory);
  const [deleteCategoryMutation] = useMutation(deleteCategory);
  const [getSignedUrlMutation] = useMutation(getSignedUrl);
  const [getObjectUrlMutation] = useMutation(getObjectUrl);

  useEffect(() => {
    if (categoryData?.getCategory) {
      const category = categoryData.getCategory;
      form.setFieldsValue({
        name: category.name,
        description: category.description,
        prompt: category.prompt,
        model: category.model,
        type: category.type,
        isExplore: category.isExplore,
      });
      setPreviews({
        video: category.video,
        thumbnail: category.thumbnail,
        gif: category.gif,
      });
    }
  }, [categoryData, form]);

  const handleFileChange = (type, file) => {
    if (file) {
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        message.error(`${type} must be smaller than 100MB!`);
        return false;
      }

      setFiles(prev => ({ ...prev, [type]: file }));
      setPreviews(prev => ({ 
        ...prev, 
        [type]: URL.createObjectURL(file) 
      }));
    }
    return false;
  };

  const getSignedUrls = async () => {
    const urls = {};
    for (const [type, file] of Object.entries(files)) {
      if (file) {
        const { data } = await getSignedUrlMutation({
          variables: { fileName: file.name }
        });
        urls[type] = data.getSignedUrl;
      }
    }
    return urls;
  };

  const handleUpdate = async (values) => {
    try {
      setLoading(true);
      const signedUrls = await getSignedUrls();
      
      if (Object.keys(signedUrls).length > 0) {
        showSnackBar('info', 'Uploading Files', 'Please wait while files are being uploaded...');
        
        const uploadPromises = Object.entries(signedUrls).map(([type, urlData]) => 
          uploadToR2(files[type], urlData.url, urlData.contentType)
        );
        await Promise.all(uploadPromises);
      }

      // Get permanent URLs
      const updatedUrls = {};
      for (const [type, urlData] of Object.entries(signedUrls)) {
        const { data } = await getObjectUrlMutation({
          variables: { key: urlData.id, eTag: 'etag' }
        });
        updatedUrls[type] = data.getObjectUrl;
      }

      await updateCategoryMutation({
        variables: {
          categoryId: id,
          name: values.name,
          description: values.description,
          prompt: values.prompt,
          model: values.model,
          type: values.type,
          isExplore: values.isExplore,
          video: updatedUrls.video || previews.video,
          thumbnail: updatedUrls.thumbnail || previews.thumbnail,
          gif: updatedUrls.gif || previews.gif,
        }
      });

      showSnackBar('success', 'Success', 'Category updated successfully');
      navigate('/categories');
    } catch (error) {
      showSnackBar('error', 'Error', error.message || 'Failed to update category');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCategoryMutation({
        variables: { categoryId: id }
      });
      showSnackBar('success', 'Success', 'Category deleted successfully');
      navigate('/categories');
    } catch (error) {
      showSnackBar('error', 'Error', 'Failed to delete category');
    }
  };

  return (
    <div className="category-detail-container">
      <Card title="Edit Category">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpdate}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please enter name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Please enter description' }]}
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item
                name="prompt"
                label="Prompt"
                rules={[{ required: true, message: 'Please enter prompt' }]}
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item
                name="model"
                label="Model"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: 'Please select type' }]}
              >
                <Select>
                  {categoryTypes.map(type => (
                    <Option key={type.value} value={type.value}>
                      {type.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="isExplore"
                label="Is Explore"
                rules={[{ required: true, message: 'Please select is explore' }]}
              >
                <Select>
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Video">
                <Upload
                  beforeUpload={file => handleFileChange('video', file)}
                  showUploadList={false}
                  accept="video/*"
                >
                  <Button icon={<UploadOutlined />}>Select Video</Button>
                </Upload>
                {previews.video && (
                  <video
                    src={previews.video}
                    controls
                    className="preview-video"
                  />
                )}
              </Form.Item>

              <Form.Item label="Thumbnail">
                <Upload
                  beforeUpload={file => handleFileChange('thumbnail', file)}
                  showUploadList={false}
                  accept="image/*"
                >
                  <Button icon={<UploadOutlined />}>Select Thumbnail</Button>
                </Upload>
                {previews.thumbnail && (
                  <Image
                    src={previews.thumbnail}
                    alt="Thumbnail"
                    className="preview-image"
                  />
                )}
              </Form.Item>

              <Form.Item label="GIF">
                <Upload
                  beforeUpload={file => handleFileChange('gif', file)}
                  showUploadList={false}
                  accept="image/gif"
                >
                  <Button icon={<UploadOutlined />}>Select GIF</Button>
                </Upload>
                {previews.gif && (
                  <Image
                    src={previews.gif}
                    alt="GIF"
                    className="preview-image"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Space>
              <Button 
                type="primary" 
                htmlType="submit" 
                icon={<SaveOutlined />}
                loading={loading}
              >
                Update
              </Button>
              <Button 
                danger
                icon={<DeleteOutlined />}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CategoryDetail; 