import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Table, Card, Tag, Image, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getExplorePage } from '../../graphql/queries';
import { useNavigate } from 'react-router-dom';
import './Explore.css';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).replace(',', ''); // 03/14/24 02:30 PM formatında gösterecek
};

const Explore = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const { loading, data } = useQuery(getExplorePage, {
    variables: {
      page: pagination.current,
      pageSize: pagination.pageSize
    }
  });

  const handleRowClick = (record) => {
    navigate(`/explore/${record.id}`);
  };

  const columns = [
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnailUrl',
      key: 'thumbnailUrl',
      width: '120px',
      render: (url) => (
        <Image
          src={url}
          alt="Thumbnail"
          style={{ width: '80px', height: '80px', objectFit: 'cover' }}
          preview={{
            maskClassName: 'customize-mask',
            mask: <div>View</div>
          }}
        />
      ),
    },
    {
      title: 'Prompt',
      dataIndex: 'prompt',
      key: 'prompt',
      width: '30%',
      render: (text) => (
        <span title={text}>
          {text?.length > 50 ? `${text.substring(0, 50)}...` : text}
        </span>
      ),
    },
    {
      title: 'Category',
      dataIndex: ['category', 'name'],
      key: 'category',
      width: '150px',
    },
    {
      title: 'GIF',
      dataIndex: 'gif',
      key: 'gif',
      width: '120px',
      render: (url) => url ? (
        <Image
          src={url}
          alt="GIF"
          style={{ width: '80px', height: '80px', objectFit: 'cover' }}
          preview={{
            maskClassName: 'customize-mask',
            mask: <div>View</div>
          }}
        />
      ) : '-',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '150px',
      render: (date) => formatDate(date),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '150px',
      render: (date) => formatDate(date),
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  return (
    <div className="explore-container">
      <Card 
        title="Explore"
        extra={
          <Button 
            type="primary" 
            icon={<PlusOutlined />}
            onClick={() => navigate('/explore/create')}
          >
            Create Explore
          </Button>
        }
      >
        <Table
          columns={columns}
          dataSource={data?.getAdminExplores || []}
          loading={loading}
          rowKey="id"
          pagination={{
            ...pagination,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            style: { cursor: 'pointer' }
          })}
        />
      </Card>
    </div>
  );
};

export default Explore; 