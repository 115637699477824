export const uploadToR2 = async (file, uploadUrl, contentType) => {
  // Validate input
  if (!file) {
    return {
      success: false,
      message: 'No file provided for upload'
    };
  }

  // Ensure the URL is a valid string
  if (!uploadUrl || typeof uploadUrl !== 'string') {
    return {
      success: false,
      message: 'Invalid upload URL'
    };
  }

  try {
    console.log("contentType: ", contentType);
    const response = await fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': contentType,
      },
      body: file
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return {
      success: true,
      response: await response.text()
    };
  } catch (error) {
    return {
      success: false,
      message: error.message || 'An unexpected error occurred during upload'
    };
  }
};