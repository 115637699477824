import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Table, Card, Button, Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getCategories } from '../../graphql/queries';
import { useNavigate } from 'react-router-dom';
import './Categories.css';

const Categories = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const { loading, data } = useQuery(getCategories);

  const handleRowClick = (record) => {
    navigate(`/categories/${record.id}`);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '100px',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
    },
    {
      title: 'GIF',
      dataIndex: 'gif',
      key: 'gif',
      width: '120px',
      render: (gif) => gif ? (
        <Image
          src={gif}
          alt="Category GIF"
          style={{ width: '80px', height: '80px', objectFit: 'cover' }}
          preview={{
            maskClassName: 'customize-mask',
            mask: <div>View</div>
          }}
        />
      ) : '-',
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  return (
    <div className="categories-container">
      <Card 
        title="Categories"
        extra={
          <Button 
            type="primary" 
            icon={<PlusOutlined />}
            onClick={() => navigate('/categories/create')}
          >
            Create Category
          </Button>
        }
      >
        <Table
          columns={columns}
          dataSource={data?.getCategories || []}
          loading={loading}
          rowKey="id"
          pagination={{
            ...pagination,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            style: { cursor: 'pointer' }
          })}
        />
      </Card>
    </div>
  );
};

export default Categories; 