import React from 'react';
import { Row, Col, Statistic } from 'antd';
import { 
  VideoCameraOutlined, 
  CheckCircleOutlined, 
  CloseCircleOutlined, 
  RollbackOutlined 
} from '@ant-design/icons';

const VideoAnalytic = ({ videoData }) => {
  const stats = [
    {
      title: 'Total Video',
      value: videoData?.total || 0,
      icon: <VideoCameraOutlined />,
    },
    {
      title: 'Succeeded',
      value: videoData?.succeeded || 0,
      icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
    },
    {
      title: 'Failed',
      value: videoData?.failed || 0,
      icon: <CloseCircleOutlined style={{ color: '#ff4d4f' }} />,
    },
    {
      title: 'Refund',
      value: videoData?.refund || 0,
      icon: <RollbackOutlined style={{ color: '#faad14' }} />,
    }
  ];

  return (
    <Row gutter={[24, 12]}>
      {stats.map((stat, index) => (
        <Col span={6} key={index}>
          <Statistic 
            title={stat.title}
            value={stat.value}
            prefix={stat.icon}
            valueStyle={{ fontSize: '18px' }}
          />
        </Col>
      ))}
    </Row>
  );
};

export default VideoAnalytic; 