import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { getAllAccounts } from '../../graphql/queries';
import { updateAccount } from '../../graphql/mutation';
import './Account.css';

const EditAccount = ({ open, onClose, accountId, accountType }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { data: accountData } = useQuery(getAllAccounts, {
    variables: { type: accountType },
    skip: !accountId,
  });

  const [updateAccountMutation] = useMutation(updateAccount, {
    refetchQueries: [{ query: getAllAccounts, variables: { type: accountType } }],
  });

  useEffect(() => {
    if (open && accountData?.getAllAccounts) {
      let account;
      switch (accountType) {
        case 'RUNWAY':
          account = accountData.getAllAccounts.runwayAccounts.find(acc => acc.id === accountId);
          break;
        case 'SORA':
          account = accountData.getAllAccounts.soraAccounts.find(acc => acc.id === accountId);
          break;
        case 'HAILUOAI':
          account = accountData.getAllAccounts.hailuoaiAccounts.find(acc => acc.id === accountId);
          break;
        default:
          break;
      }

      if (account) {
        form.setFieldsValue({
          accountName: account.accountName,
          token: account.token,
          ...(accountType === 'RUNWAY' && {
            accountNo: account.accountNo,
            teamId: account.teamId,
          }),
          ...(accountType === 'HAILUOAI' && {
            accountNo: account.accountNo,
            teamId: account.teamId,
          }),
        });
      }
    }
  }, [open, accountData, accountId, form, accountType]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const { data } = await updateAccountMutation({
        variables: {
          accountId,
          token: values.token,
          type: accountType,
        },
      });

      if (data.updateAccount.success) {
        message.success(data.updateAccount.message || 'Account updated successfully');
        onClose();
        form.resetFields();
      } else {
        message.error(data.updateAccount.message || 'Failed to update account');
      }
    } catch (error) {
      message.error(`Error updating account: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const renderFormItems = () => {
    const baseItems = [
      <Form.Item
        key="accountName"
        name="accountName"
        label="Account Name"
        rules={[{ required: true }]}
      >
        <Input disabled />
      </Form.Item>,
      <Form.Item
        key="token"
        name="token"
        label="Token"
        rules={[{ required: true }]}
      >
        <Input.Password />
      </Form.Item>,
    ];

    if (accountType === 'RUNWAY' || accountType === 'HAILUOAI') {
      return [
        ...baseItems,
        <Form.Item
          key="accountNo"
          name="accountNo"
          label="Account No"
        >
          <Input disabled />
        </Form.Item>,
        <Form.Item
          key="teamId"
          name="teamId"
          label="Team ID"
        >
          <Input disabled />
        </Form.Item>,
      ];
    }

    return baseItems;
  };

  return (
    <Modal
      title={`Edit ${accountType} Account`}
      open={open}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      okText="Update"
      confirmLoading={loading}
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        {renderFormItems()}
      </Form>
    </Modal>
  );
};

export default EditAccount; 